import React from "react";
import BrokerBanner from "./BrokerBanner";
import BrokerList from "./BrokerList";
import YoutubeSection from "./YoutubeSection";
import ForexBrokerHelmet from "../Helmets/ForexBrokerHelmet";


export default function Index() {
  return (
    <div className="container">
      <ForexBrokerHelmet />
      <BrokerBanner />
      <BrokerList />
      <YoutubeSection />
      
    </div>
  );
}
