import React from "react";
import TickerTape from "./TickerTape";
import ForexCalenderHelmet from "../Helmets/ForexCalenderHelmet";
//import Partner from "../Homepage/Partner";

export default function Index() {
  return (
    <div>
      <ForexCalenderHelmet />
      <TickerTape />
      
    </div>
  );
}
