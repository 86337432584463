import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

export default function Modal() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Get current date
    const currentDate = new Date();
    const startDate = new Date('2024-10-21');
    const endDate = new Date('2024-11-17');

    // Check if the current date is between the start and end dates
    if (currentDate >= startDate && currentDate <= endDate) {
      setIsOpen(true); // Show modal if within range
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full relative">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={() => setIsOpen(false)}
            >
              <XMarkIcon className="h-6 w-6 bg-red-400 rounded-sm" />
            </button>
            {/* <h2 className="text-xl text-sky-600 font-medium bg-sky-50 mb-4">Diwali Promotion</h2>
            <p className="mb-4">Celebrate Diwali with our exciting offers!</p> */}
            <img src="images/diwali tmmm2.jpg" alt="Diwali Promotion" className="mb-0" />
          </div>
        </div>
      )}
    </>
  );
}
