import React from "react";
import YoutubeBanner from "./YoutubeBanner";
import VideoList from "./VideoList";
import YoutubeHelmet from "../Helmets/YoutubeHelmet";


export default function Index() {
  return (
    <div className="container">
      <YoutubeHelmet />
      <YoutubeBanner />
      <VideoList />
      
    </div>
  );
}
