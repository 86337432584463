import React from "react";
import CoursesBanner from "./CoursesBanner";
// import CourseContents from "./CourseContents";
import Testimonial from "./Testimonial";
import Feedback from "./Feedback";
import ForexCourseHelmet from "../Helmets/ForexCourseHelmet";
import CourseStructure from "./CourseStructure";


export default function Index() {
  return (
    <div className="container">
      <ForexCourseHelmet />
      <CoursesBanner />
      <CourseStructure/>
      {/* <CourseContents /> */}
      <Testimonial />
      <Feedback />
      
    </div>
  );
}
