import React from "react";
import DownloadResources from "./DownloadResources";
import ResourceBanner from "./ResourceBanner";
import ResourcesHelmet from "../Helmets/ResourcesHelmet";


export default function Index() {
  return (
    <div className="container">
      <ResourcesHelmet />
      <ResourceBanner />
      <DownloadResources />
      
    </div>
  );
}
