import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function ActionCourse() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <section className="container mx-auto p-0 md:py-20 px-0 md:p-10 md:px-0">
        <section className="relative px-0 md:p-0 transform duration-500 hover:shadow-2xl cursor-pointer hover:-translate-y-1 ">
          <img
            data-aos="fade-down"
            data-aos-delay="600"
            className="xl:max-w-6xl rounded-lg hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
            src="./images/iStock-1358050785 (1).webp"
            alt=""
          />
          <div
            data-aos="zoom-in-down"
            className="content bg-gray-900 bg-opacity-70 rounded-lg p-2 pt-8 md:p-12 pb-12 lg:max-w-lg w-full lg:absolute top-48 right-5 hover:shadow-[0px_10px_30px_rgba(225,_212,_247,_1)]"
          >
            <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none md:mb-6 group">
              Forex Price Action
              <br />
              Course
            </h2>
            {/* <h2 className="text-3xl font-semibold mt-4 md:mt-10">
              Coffee From Heaven
            </h2> */}
            <p className="my-3 text-justify font-medium xl:text-lg text-gray-50 leading-relaxed ">
              Are you ready to elevate your forex trading skills and take your
              financial journey to the next level? Join me, Kulwant Rana, in
              this exclusive 1-month online course where I personally guide you
              through the intricacies of forex trading through the lens of price
              action.
            </p>
            <Link
              className="block lg:w-1/2 w-full btn-forex"
              to="https://forexsalary.rpy.club/cohort/8x8CF7FnIU"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </Link>
          </div>
        </section>
      </section>
    </div>
  );
}
