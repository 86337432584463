import React from "react";
import TradingTool1 from "./TradingTool1";
import TickerTape from "./TickerTape";
import TechnicalSignals from "./TechnicalSignals";
import StockNews from "./StockNews";
import StockIndices from "./StockIndices";
import StockTradingHelmet from "../Helmets/StockTradingHelmet";


export default function Index() {
  return (
    <div className="container">
      <StockTradingHelmet />
      <TradingTool1 />
      <TickerTape />
      <TechnicalSignals />
      <StockIndices />
      <StockNews />
      
    </div>
  );
}
